import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import { ACCEPTED_QUERIES } from 'config/constants/common';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';
import { HUMAN_CENTERED_STRATEGY_SPLIT_VALUES } from 'config/constants/splits';
import { API_NAMES } from 'typings/Config';

export const selectRuntime = (state: IAppState): Runtime => state.runtime;

export const selectApiConfig = (state: IAppState) =>
  selectRuntime(state).apiConfig;

export const selectApiConfigByName =
  (apiName: API_NAMES) => (state: IAppState) =>
    selectApiConfig(state)[apiName] ?? selectApiConfig(state).default;

export const selectApiUrlByName =
  (apiName: API_NAMES) => (state: IAppState) => {
    const value = selectApiConfigByName(apiName)(state);

    if (typeof value === 'string') return value;

    return value.url;
  };

export const selectProject = (state: IAppState) => selectRuntime(state).project;

export const selectProjectId = (state: IAppState): Runtime['project']['id'] =>
  selectProject(state).id;

export const selectProjectDetails = (
  state: IAppState,
): Runtime['project']['details'] => selectProject(state).details;

export const selectTeamDescription = (state: IAppState) =>
  selectProject(state).team_description;

export const selectCurrentServerTime = (state: IAppState) =>
  selectRuntime(state).currentServerTime;

export const selectProjectAlias = (state: IAppState): NEWS_PROJECT_ALIASES =>
  selectProject(state).alias;

export const selectProjectName = (state: IAppState) =>
  selectProject(state).name;

export const selectProjectCommentsAppId = (state: IAppState) =>
  selectProject(state).comments_appid;

export const selectRuntimeProfile = (state: IAppState) =>
  selectRuntime(state).profile;

export const selectDebuggerConfig = (state: IAppState) =>
  selectRuntime(state).debuggerConfig;

export const selectCustomDebug = (state: IAppState) =>
  selectDebuggerConfig(state).customDebug;

export const selectCustomClusterFeed = (state: IAppState) =>
  selectCustomDebug(state).clusterFeed;

export const selectSplits = (state: IAppState) =>
  selectDebuggerConfig(state).splits;

export const selectSplitsConfig = (state: IAppState) =>
  selectDebuggerConfig(state).splitsConfig;

export const selectSwitchers = (state: IAppState) =>
  selectDebuggerConfig(state).switchers;

export const selectDisableAds = (state: IAppState) =>
  selectSwitchers(state)[ACCEPTED_QUERIES.disableAds].value;

export const selectDisableAnalytics = (state: IAppState) =>
  selectSwitchers(state)[ACCEPTED_QUERIES.disableAnalytics].value;

export const selectForceAdFallback = (state: IAppState) =>
  selectSwitchers(state)[ACCEPTED_QUERIES.forceAdFallback].value;

export const selectPromoWidgetEnabled = (state: IAppState) =>
  selectSwitchers(state)[ACCEPTED_QUERIES.promoWidgetEnabled].value;

export const selectPromoWidgetPlaceholderEnabled = (state: IAppState) =>
  selectSwitchers(state)[ACCEPTED_QUERIES.promoWidgetPlaceholderEnabled].value;

export const selectGigaEnabled = (state: IAppState) =>
  selectSwitchers(state)[ACCEPTED_QUERIES.gigaEnabled].value;

export const selectHumanCenteredStrategyValueFromSplits = (
  splits: Runtime['debuggerConfig']['splits'],
) => splits?.humanCenteredStrategy as HUMAN_CENTERED_STRATEGY_SPLIT_VALUES;

export const selectHumanCenteredStrategyValue = (state: IAppState) =>
  selectHumanCenteredStrategyValueFromSplits(selectSplits(state));

export const selectIsHumanCenteredStrategyStateActivated = (
  state: IAppState,
): boolean =>
  selectHumanCenteredStrategyValueFromSplits(selectSplits(state)) ===
  HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on;

export const selectAdtech = (state: IAppState) => selectRuntime(state).adtech;

export const selectRcmId = (state: IAppState): string =>
  selectAdtech(state).rcmID;

export const selectRecommendBlockID =
  (recommendBlockID: RCM_BLOCK_TYPE | undefined) => (state: IAppState) =>
    recommendBlockID
      ? selectAdtech(state).recommendBlockID[recommendBlockID]
      : '';

export const selectAdtechUid = (state: IAppState): string =>
  selectAdtech(state).uid || '';

export const selectAdtechScope = (state: IAppState): string =>
  selectAdtech(state).scope || '';

export const selectRuid = (state: IAppState): string =>
  selectRuntime(state).ruid || '';

export const selectUserId = (state: IAppState): string =>
  selectRuntime(state).userId ?? null;

export const selectDomainConfig = (state: IAppState) =>
  selectRuntime(state).domainConfig;

export const selectPostfix = (state: IAppState) =>
  selectDomainConfig(state).postfix;

export const selectProtocol = (state: IAppState) =>
  selectDomainConfig(state).protocol;

export const selectBanners = (state: IAppState) => selectRuntime(state).banners;

export const selectCommercialS2S =
  (clusterId: number | undefined | null) => (state: IAppState) =>
    clusterId ? selectRuntime(state).commercialS2S?.[clusterId] : null;

export const selectSettings = (state: IAppState): Runtime['settings'] =>
  selectRuntime(state).settings;

export const selectAntiadblockEnabled = (state: IAppState) =>
  selectSettings(state).antiadblockEnabled;

export const selectIsDebug = (state: IAppState) =>
  selectSettings(state).isDebug;

export const selectIsRussia = (state: IAppState) =>
  selectSettings(state).isRussia;

export const selectIsMobile = (state: IAppState) =>
  selectSettings(state).isMobile;

export const selectIsBot = (state: IAppState) => selectSettings(state).isBot;

export const selectVersion = (state: IAppState) => selectRuntime(state).version;

export const selectVariables = (state: IAppState) =>
  selectRuntime(state).variables || {};

export const selectVariablesBannedCommentsTopics = (state: IAppState) =>
  selectVariables(state).topicsWithDisabledComments || '';

export const selectIsBannedCommentsTopic =
  (topicAlias: string | undefined) => (state: IAppState) =>
    selectVariablesBannedCommentsTopics(state).includes(topicAlias as string);

export const selectSberWidgetErid = (state: IAppState) =>
  selectVariables(state).sberWidgetErid || '';

export const selectVariablesBatchInterval = (state: IAppState) => {
  const value = Number(selectVariables(state).metricsBatchInterval);
  // Защита от дурака, если кто-то проставит значение, меньше 10
  // 10 - дефолтное значение, плюс интервал ниже 10 будет убийственным для прода
  if (Number.isNaN(value) || value < 10) return undefined;

  return value;
};

export const selectVariablesBatchSamplePercent = (state: IAppState) => {
  const value = Number(selectVariables(state).metricsBatchSamplePercent);
  if (Number.isNaN(value) || value > 100 || value < 0) return undefined;

  return value;
};

/**
 * Получение шанса, что когда клиент уходит со страницы, то данные будут отправлены.
 * Да, для этого сделана отдельная метрика.
 * Если удасться стабилизировать работу прода или вынести код по сбору метрик с клиента
 *  на другое приложение, то этот параметр будет не нужен.
 */
export const selectVariablesBatchSampleClientLeavePercent = (
  state: IAppState,
) => {
  const value = Number(
    selectVariables(state).metricsBatchSampleClientLeavePercent,
  );
  if (Number.isNaN(value) || value > 100 || value < 0) return undefined;

  return value;
};
